import { render } from "./SpaceFormPageTwo.vue?vue&type=template&id=59760fc5"
import script from "./SpaceFormPageTwo.vue?vue&type=script&lang=js"
export * from "./SpaceFormPageTwo.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "59760fc5"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('59760fc5', script)) {
    api.reload('59760fc5', script)
  }
  
  module.hot.accept("./SpaceFormPageTwo.vue?vue&type=template&id=59760fc5", () => {
    api.rerender('59760fc5', render)
  })

}

script.__file = "src/components/dashboard/space-form-pages/SpaceFormPageTwo.vue"

export default script